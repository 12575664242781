import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux'
import { darken, lighten } from 'polished'
import styled from 'styled-components'
import { getChallenges } from 'core'
import colors from 'app/lib/colors'
import Icon from 'app/components/Icon'
import Analytics from 'app/lib/analytics'
import t from 'app/lib/i18n'
import _Cards from 'app/components/Cards'
import DietTierBadge from 'app/components/DietTierBadge'
import _DietCards from 'app/components/DietCards'
import DietDetails from 'app/components/DietDetails'
import { default as _Button } from 'app/components/Button'
import BasicWorkflowCheckin from 'app/views/Dashboard/BasicWorkflowCheckin'
import NavigationPath from 'app/views/navigation/NavigationPath'
import FoodGroupInfo from 'app/views/Workflow/side-by-side/components/FoodGroupInfo'
import ShareInterface from 'app/components/ShareInterface'
import Recipes from 'app/components/Recipes'
import ShoppingList from 'app/components/ShoppingList'
import { Header } from 'app/components/Core'
import D2CCard from 'app/components/D2CCard'

import NaturesBountyProducts from 'app/custom/naturesbounty/NaturesBountyProducts'

import { getMealPlan } from 'app/lib/util'
import * as constants from 'app/lib/constants'
import * as actions from 'app/actions/workflow'
import * as navActions from 'app/actions/navigation'
import * as generalActions from 'app/actions/general'

import TrackingDashboard from './TrackingDashboard'
import { getUrlParam } from "../../lib/util";

const Button = styled(_Button)``

const DietCards = styled(_DietCards)`
  margin-top: 25px;
`

const Wrap = styled.div`
  position: relative;

  .disclaimer {
    font-size: 12px;
    color: ${colors.gray};
    margin: 40px 20px;
    text-align: center;
  }

  .buttonWrap {
    margin: 0 30px;

    ${Button} {
      display: block;
      width: 200px;
      margin: 10px auto;
    }
  }

  .help-icon {
    position: absolute;
    top: 22px;
    left: 25px;
    color: ${(props) => props.theme.primary};
    font-size: 25px;
    cursor: pointer;
  }

  .user-icon {
    position: absolute;
    top: 25px;
    left: 55px;
    color: ${(props) => props.theme.secondary};
    font-size: 25px;
    cursor: pointer;
  }

  .share-interface {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .button {
    cursor: pointer;
    text-align: center;
    padding: 12px;
    background: ${(props) => props.theme.primary};
    color: white;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
    font-weight: bold;
    max-width: 320px;
  }

  .powered-by {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-top: 15px;

    @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
      display: none;
    }
  }
`

const SecondaryButton = styled(Button)`
  background: ${(props) => props.theme.secondary};

  &:hover {
    background: ${(props) => darken(0.025, props.theme.secondary)};
  }
`

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
`

const BadgeWrap = styled.div`
  position: relative;
  padding: 25px 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 500px;
  margin: 12px auto 40px auto;
  background: ${colors.greyBackground};

  .inner-wrap {
    flex: 1;
    margin: 0 12px;
    text-align: center;
  }

  ${Button} {
    margin-top: 20px;
    display: ${(props) => (props.showButtons ? 'block' : 'none')};
  }

  .ideal-info-label {
    font-size: 14px;
    text-align: center;
    margin-top: 8px;
  }

  .arrow {
    width: 50px;
    margin: 60px 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 375px) {
      width: 35px;
      margin: 0 10px;
      margin-bottom: 60px;
    }

    @media (max-width: 330px) {
      width: 15px;
      margin: 0 5px;
      margin-bottom: 60px;
    }

    :before {
      content: ' ';
      width: 30px;
      background: ${colors.gray};
      height: 2px;
    }
    :after {
      content: ' ';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 10px solid ${colors.gray};
    }
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH - 1}px) {
    align-items: center;

    .inner-wrap:first-child {
      margin-bottom: 40px;
    }
  }

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    flex-direction: row;
  }
`

const Logo = styled.img`
  display: block;
  margin: 15px auto 0px;
  max-width: 120px;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    display: none;
  }
`

const DietLabel = styled.div`
  display: inline-block;
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.primary};
  border-bottom: 1px solid ${(props) => lighten(0.3, props.theme.primary)};
`

const D2CHeader = styled(Header)`
  font-size: 1.8em;
  color: #196161;
  font-weight: normal;
`

const Dashboard = ({ scrollRef, partner, data, workflow, general, dispatch }) => {
  const { currentState: state } = workflow

  const navigationActive = state.navigation_active
  const idDiet = state.diet_id_id ? data.diet[state.diet_id_id] : null
  const idealDiet = state.diet_ideal_id ? data.diet[state.diet_ideal_id] : null
  const idIsIdeal = state.diet_id_id === state.diet_ideal_id
  const d2c = partner.code === 'd2cfreetrial'
  const nbty = partner.code === 'nbty'
  const tracking = state.tracking && state.tracking.length > 1

  const toggleSettings = () => dispatch(generalActions.openSettings())
  const startWorkflow = (type) => dispatch(actions.startNewWorkflow(type))
  const startNavigation = () => (window.location.href = '/navigation/start')

  if (!state.initialized || tracking) {
    // || getUrlParam('detailed')
    return <TrackingDashboard />
  }

  const updateButton = (
    <div className="buttonWrap">
      <Button
        text={t(nbty ? 'Retake the quiz' : partner.disable_ideal ? 'Update ID' : 'Update ID & Goal')}
        onClick={() => startWorkflow()}
      />
    </div>
  )

  const challenges = useMemo(
    () =>
      d2c && !navigationActive && idDiet && idealDiet ? getChallenges(idDiet, idealDiet, state.diet_restrictions) : [],
    [d2c, navigationActive, idDiet, idealDiet, state.diet_restrictions]
  )

  const renderDietCards = () => {
    return (
      <div>
        <DietCards
          idNutrition={state.diet_id_nutrition_info}
          idealNutrition={state.diet_ideal_nutrition_info}
          goals={d2c ? null : state.goals}
          gender={state.user_info.gender}
        />{/*
        here 1
        {JSON.stringify(workflow)}*/}
        <DietDetails
          partner={partner}
          idNutrition={state.diet_id_nutrition_info}
          idealNutrition={state.diet_ideal_nutrition_info}
          healthGoals={state.goals.map((goal) => goal.value)}
          scrollRef={scrollRef}
          workflowID={workflow.id}
        />
      </div>
    )
  }

  return (
    <Wrap>
      {!navigationActive && (
        <>
          {!general.viewCustomizations.nohelp && (
            <a
              href="https://dietid.com/support"
              onClick={() => {
                Analytics.trackPageView('/support')
              }}
              target="_blank"
              className="help-icon"
              rel="noopener noreferrer"
            >
              <Icon name="help-circle" />
            </a>
          )}
          <div onClick={toggleSettings} className="user-icon">
            <Icon name="user-circle" className="" />
          </div>
        </>
      )}
      <ShareInterface dispatch={dispatch} workflow={workflow} />
      <Logo src={partner.logo_small_url} />
      {partner.show_powered_by_diet_id && <div className="powered-by">{t('Powered by Diet ID')}</div>}
      <Header>{t('Assessment')}</Header>
      <BadgeWrap showButtons={!state.should_ask_for_basic_checkin}>
        {idDiet && (
          <div className="inner-wrap">
            <DietLabel>{t('Your ID')}</DietLabel>
            <br />
            <DietTierBadge
              partner={partner}
              color={partner.primary_color}
              diet={idDiet}
              dietRestrictions={state.diet_restrictions}
              showPhoto
            />
            {(!partner.navigation_active || !navigationActive || partner.disable_ideal) && idealDiet && (
              <Button text="Update ID" onClick={() => startWorkflow(idealDiet ? 'id' : null)} />
            )}
          </div>
        )}
        {idealDiet && (
          <div className="inner-wrap" key="badge">
            <DietLabel>{t('Goal')}</DietLabel>
            <br />
            <DietTierBadge
              partner={partner}
              color={partner.secondary_color}
              diet={idealDiet}
              dietRestrictions={state.diet_restrictions}
              showPhoto
            />
            {(!partner.navigation_active || !navigationActive) && (
              <SecondaryButton text="Update Goal" onClick={() => startWorkflow('ideal')} />
            )}
            {state.diet_ideal_info_label && <div className="ideal-info-label">{t(state.diet_ideal_info_label)}</div>}
            {idealDiet.code ? (
              <Button text="View Meal Plan" onClick={() => window.open(getMealPlan(idealDiet.code, partner.custom_meal_plans ? partner.code : false))} />
            ) : null}
          </div>
        )}
      </BadgeWrap>
      {state.should_ask_for_basic_checkin && <BasicWorkflowCheckin />}
      {(idDiet || idealDiet) && (
        <>
          {d2c && updateButton}
          {!idIsIdeal && state.diet_ideal && state.to_increase && state.to_decrease && (
            <FoodGroupInfo
              style={{ padding: 0, maxWidth: '90%', margin: 'auto' }}
              highlightColor={partner.primary_color}
              includesTitle="Increase"
              food_groups_included={state.to_increase
                .map((v) => t(v))
                .slice(0, 4)
                .join(', ')}
              lacksTitle="Decrease"
              food_groups_lacking={state.to_decrease
                .map((v) => t(v))
                .slice(0, 4)
                .join(', ')}
              diet_restrictions={state.diet_restrictions}
              d2c={d2c}
            />
          )}
          {!d2c && updateButton}
        </>
      )}
      {/*disabled for prod*/}
      {/*idealDiet && <Recipes diet_restrictions={state.diet_restrictions} idealDiet={idealDiet} />*/}
      {d2c && (
        <>
          <NavigationPath idDiet={idDiet} idealDiet={idealDiet} challenges={challenges} partner={partner} d2c />
          <Recipes />
          <ShoppingList />
        </>
      )}
      {idDiet && idealDiet && partner.navigation_active && !navigationActive && (
        <div className="buttonWrap">
          <Button text="Start challenges" onClick={startNavigation} />
        </div>
      )}
      {d2c ? (
        <D2CCard>
          <D2CHeader>{t('Focus on improving these nutrients')}</D2CHeader>
          {renderDietCards()}
        </D2CCard>
      ) : (
        renderDietCards()
      )}
      {nbty && (
        <NaturesBountyProducts
          idDietCode={idDiet.code}
          idNutrition={state.diet_id_nutrition_info}
          userInfoCustomField={state.custom_data}
        />
      )}
      {nbty && (
        <div className="disclaimer">
          {t(
            'Health is personal. If you are pregnant, nursing, taking any medications, have been diagnosed with any medical condition, or are planning any medical or surgical procedure, please consult your doctor before taking any new supplements.'
          )}
        </div>
      )}
      {!nbty && state.disclaimer && <div className="disclaimer">*{t(state.disclaimer)}</div>}
    </Wrap>
  )
}

const mapStateToProps = (state, ownProps) => ({
  partner: state.general.partnerId ? state.data.partner[state.general.partnerId] : null,
  data: state.data,
  workflow: state.workflow,
  general: state.general
})

export default connect(mapStateToProps)(Dashboard)
