import { getClientBetaHost } from 'core/src/lib/constants'

export const redirectToNewApp = (redirect = true) => {
  const authKey = localStorage.getItem('DQPN_AUTH')
  if (!authKey) {
    console.error('No auth key found')
    return
  }
  let subdomain = window.location.origin.split('.')[0]
  subdomain = subdomain.split('://')[1]
  const hashedKey = btoa(authKey)
  const newAppUrl = `${getClientBetaHost(subdomain)}/auth?auto_auth=${encodeURIComponent(hashedKey)}`
  console.log('newAppUrl1', newAppUrl)
  if (redirect && newAppUrl) {
    console.log('newAppUrl redirecting...', newAppUrl)
    window.location.href = newAppUrl // Redirect to the new app
  } else {
    return newAppUrl // Return the URL for use in a link
  }
}
export const getUrlParam = (name) => {
  const url = window.location.search
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}
// https://davidwalsh.name/query-string-javascript
export const getUrlParamOld = (name) => {
  name = name.replace(/[[]/, '[').replace(/[\]]/, ']')

  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(window.location.search)

  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const getMealPlan = (idealDietCode, partnerCode) => {
  /*
  const { partnerId } = useSelector((state) => state ? state.general : undefined)
  const { partner } = useSelector((state) => state.data)
  const Partner = useMemo(() => (partner && partnerId ? partner[partnerId] : { custom_meal_plans: false }), [
    partner,
    partnerId
  ])*/
  const prefix = 'https://s3.amazonaws.com/app.dietid.com/meal_plans/'
  const suffix = '_Meal_Plan_Diet_ID.pdf'
  return partnerCode ? prefix + partnerCode + '_' + idealDietCode + suffix : prefix + idealDietCode + suffix
  /*
    return idealDietCode ? 'https://s3.amazonaws.com/app.dietid.com/meal_plans/' + idealDietCode + '_Meal_Plan_Diet_ID.pdf' : null

  const mealPlanMap = {
    AHA: 'https://www.dropbox.com/s/wdvity0skort78n/AHA%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    AME: 'https://www.dropbox.com/s/sa3j6mnkik42e2g/American%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    DSH: 'https://www.dropbox.com/s/fxhjszfmi11hb09/DASH%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    FLX: 'https://www.dropbox.com/s/z7qgdg5bjoxdp7i/Flexitarian%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    LOC: 'https://www.dropbox.com/s/geqdl4tiy6oml3d/Low%20Carb%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    LOF: 'https://www.dropbox.com/s/jldwlm3z22myrje/Low%20Fat%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    MED: 'https://www.dropbox.com/s/nyzvf4h997fpc9a/Mediterranean%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    MND: 'https://www.dropbox.com/s/x37vi8eqyl398m6/MIND%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    NRM: 'https://www.dropbox.com/s/ykx1a9mt1zcvah4/No%20Red%20Meat%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    PAL: 'https://www.dropbox.com/s/vgemrzo38um3dx6/Paleo%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    PES: 'https://www.dropbox.com/s/gd0lwt76apkq7os/Pescatarian%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    SOU: 'https://www.dropbox.com/s/ujghs6mljix24yz/Southern%20American%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    VEG: 'https://www.dropbox.com/s/0ukge5tf963vmdf/Vegan%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    VET: 'https://www.dropbox.com/s/vgay53biexwj3s7/Vegetarian%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    TLC: 'https://www.dropbox.com/s/rqbh6dfn4vobkfi/TLC%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    WFP: 'https://www.dropbox.com/s/k0yehqiihfc9me0/Whole%20Foods%20Plant%20Based%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    WFR: 'https://www.dropbox.com/s/zm5shcuv5klsd0s/Whole%20Foods%20Plant%20Based%20Fat-Restricted%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    MEX: 'https://www.dropbox.com/s/t26zefc69n9ujmo/Mexican-American%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    SA: 'https://www.dropbox.com/s/xhv16kfq4grn5u8/South%20Asian%20NON-Veg%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    SA_V: 'https://www.dropbox.com/s/0g5stg1auedxuyr/South%20Asian%20Vegetarian%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    ORN: 'https://www.dropbox.com/s/80myrjgoao7lm7l/Ornish%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    POF: 'https://www.dropbox.com/s/spfkl5v658mxpsr/Portfolio%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    CES: 'https://www.dropbox.com/s/8kkz5kls5dpewb4/Continental%20European%20South%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    CEN: 'https://www.dropbox.com/s/8g3go7snj2x41p1/Continental%20European%20North%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    AME_GF:'https://www.dropbox.com/s/ewoac17fewcadrq/American%20Meal%20Plan%20Diet%20ID%20Gluten%20Free.pdf?dl=1',
    LFM:'https://www.dropbox.com/s/d9vag971fpbwv4o/Low%20FODMAP%20Meal%20Plan%20Diet%20ID.pdf?dl=1',
    VET_GF:'https://www.dropbox.com/s/kry8k3w3dn9ltxr/Vegetarian%20Meal%20Plan%20Diet%20ID%20Gluten-Free.pdf?dl=1',
    VEG_GF:'https://www.dropbox.com/s/1jupv9472crgceb/Vegan%20Meal%20Plan%20Diet%20ID%20Gluten-Free.pdf?dl=1'
  }

  return mealPlanMap[idealDietCode] || null
  */
}

// @todo Drop this, and instead have a qualifying message below the components this is referenced from
export const filterRestrictionsFromString = (string) => string
